import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'

export interface WriteFileParams {
  filename: string
  data: string
  encoding: 'UTF8' | 'UTF16' | 'ASCII'
}

interface IFileSystemCapacitor {
  writeFile: ({ filename, data, encoding }: WriteFileParams) => Promise<void>
}

export default class FileSystemCapacitor implements IFileSystemCapacitor {
  async writeFile ({ filename, data, encoding = 'UTF8' }: WriteFileParams) {
    await Filesystem.writeFile({
      path: `${filename}`,
      data,
      directory: Directory.Documents,
      encoding: Encoding[encoding],
      recursive: true
    })
  }
}
